import logo from './logo.svg';
import './App.css';

function App() {
  return <>
    <div className='bg-gray-900 h-screen flex flex-col justify-center '>
      <div className='items-center justify-center flex flex-col'>
          <p className='text-[50px] text-white font-bold'>Adbox</p>
          <p className='text-xs text-white'>precision-targeted ai-powered ads for optimal campaign performance - in a box</p>
          <div>

          </div>
      </div>
    </div>
  </>
}

export default App;
